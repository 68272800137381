.double-section{
    padding: 8.8rem 0rem;
    .double-section--grid-6{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2.8rem;
        align-items: center;
    }
    .double-section--img-center{
        text-align: center;
    }
}
// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .double-section{
        .double-section--grid-6{
            display: grid;
            grid-template-columns: 40% 60%;
            gap: 2.8rem;
            align-items: center;
        }
    }
}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}