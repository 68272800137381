.double-section {
  padding: 8.8rem 0rem;
}
.double-section .double-section--grid-6 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.8rem;
  align-items: center;
}
.double-section .double-section--img-center {
  text-align: center;
}

@media (min-width: 992px) {
  .double-section .double-section--grid-6 {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 2.8rem;
    align-items: center;
  }
}